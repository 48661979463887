import React, {useState, useRef, useEffect} from "react";
import axios from "axios";

const AudioRecorder = ({leadData, setLeadData, needRecording}) => {
    const [isRecording, setIsRecording] = useState(false);
    const [isSend, setIsSend] = useState("");
    const [audioURL, setAudioURL] = useState(null);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);

    useEffect(() => {
        if (needRecording) {
            startRecording(leadData)
            console.log("START RECORD", leadData)
        }
        else if (!needRecording && isRecording) {
            stopRecording()
            console.log("STOP RECORD")
        }
    }, [needRecording]);

    const startRecording = async (leadData) => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream);

            mediaRecorderRef.current.ondataavailable = (event) => {
                audioChunksRef.current.push(event.data);
            };

            mediaRecorderRef.current.onstop = () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
                audioChunksRef.current = [];

                const formData = new FormData();
                formData.append("file", audioBlob, "recording.wav");

                const startTime = performance.now();
                axios.post("https://crm.caltat.ru/call_records/api/record_files", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    params: {
                        call_id: leadData.call_id,
                        project_id: leadData.project_id,
                        operator_id: leadData.operator_id,
                        operator_name: leadData.operator_name,
                    }
                })
                .then((response) => {
                    const endTime = performance.now();

                    console.log("RESPONSE TIME", ((endTime - startTime) / 1000).toFixed(2))

                    console.log("File uploaded successfully:", response.data);
                    setIsSend("SEND")
                })
                .catch((error) => {
                    console.error("Error uploading file:", error);
                    setIsSend("ERROR")
                });
            };

            mediaRecorderRef.current.start();
            setIsRecording(true);
        } catch (err) {
            console.error("Error accessing microphone:", err);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
            setLeadData(null)
        }
    };

    return null
};

export default AudioRecorder;
