
export const apiConfig = {
    url: "https://ucall.center/api",
    wsUrl: "wss://ucall.center/ws"
}

// export const apiConfig = {
//     url: "http://localhost:8000/api",//"https://wa3.cheri.tech/api/dev",
//     wsUrl: "ws://127.0.0.1:1234"//wss://wa3.cheri.tech/ws/dev"
// }
