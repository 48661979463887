import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import Header from "../../components/header";
import axios from "axios";
import {ClipLoader} from "react-spinners";
import Select from "react-select";
import MainMenu from "../../components/MainMenu";
import {apiConfig} from "../../config";

import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import DateTimePicker from "react-datetime-picker";
import ProjectsStatComp from "../../components/projectsStatistic";
import ProjectCallsStatComp from "../../components/projectCallsStatistic";


const StatisticPage: React.FC = () => {
    const [loading, setLoading]: [any, any] = useState(false)
    const [selectedStat, setSelectedStat]: [any, any] = useState("2")

    return (
        <div style={{display: "flex", flexDirection: "column", height: "100vh"}}>
            <Header title={"Статистика"}/>
            <div style={{display: "flex", flex: 1, flexDirection: "row"}}>
                <MainMenu />
                <div style={{display: "flex", height: 'calc(100vh - 60px)', flex: 1, flexDirection: "column"}}>
                    <div style={{display: "flex", flexDirection: "row", height: 50}}>
                        <button
                          onClick={() => {
                              setSelectedStat("0")
                          }}
                          style={{
                              display: "flex",
                              flex: 1,
                              borderWidth: 1,
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: selectedStat === "0" ? "white" : ""
                          }}>
                            По операторам
                        </button>
                        <button
                          onClick={() => {
                              setSelectedStat("1")
                          }}
                          style={{
                              display: "flex",
                              flex: 1,
                              borderWidth: 1,
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: selectedStat === "1" ? "white" : ""
                          }}>
                            По звонкам
                        </button>
                        <button
                          onClick={() => {
                              setSelectedStat("2")
                          }}
                          style={{
                              display: "flex",
                              flex: 1,
                              borderWidth: 1,
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: selectedStat === "2" ? "white" : ""
                          }}>
                            Проекты и звонки
                        </button>
                        <button
                          onClick={() => {
                              setSelectedStat("3")
                          }}
                          style={{
                              display: "flex",
                              flex: 1,
                              borderWidth: 1,
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: selectedStat === "3" ? "white" : ""
                          }}>
                            Проекты
                        </button>
                    </div>
                    {selectedStat === "1" && <CallsStatComp setLoading={setLoading}/>}
                    {selectedStat === "0" && <OperatorStatComp setLoading={setLoading}/>}
                    {selectedStat === "2" && <ProjectCallsStatComp setLoading={setLoading}/>}
                    {selectedStat === "3" && <ProjectsStatComp setLoading={setLoading}/>}
                </div>
            </div>
            {loading &&
              <div style={{
                  fontSize: 30,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(100, 100, 100, 0.2)",
                  color: "white",
                  display: "flex",
                  paddingTop: "15%",
                    justifyContent: "center",
                }}>
                    <ClipLoader size={150} color={"black"}/>
                </div>
            }
        </div>
    );
};


const OperatorStatComp = (props: any) => {
    const [responseOperatorsStat, setResponseOperatorsStat]: [any, any] = useState(null)
    const [selectOperator, setSelectOperator]: [any, any] = useState(null)
    const [selectOperatorsAll, setSelectOperatorsAll]: [any, any] = useState([])
    const [startDate, setStartDate]: [any, any] = useState(new Date().setHours(0, 0));

    const currentDate: any = (new Date())
    currentDate.setDate(currentDate.getDate() + 1);
    const [endDate, setEndDate]: [any, any] = useState(currentDate.setHours(0,0,0));

    const getUsers = async () => {
        const config = {
            method: 'get',
            url: `${apiConfig.url}/auth/list`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            return await axios(config);
        } catch (error) {
            return {
                data: []
            }
            console.error('error', error);
        }
    }

    const  getOperatorsStat = async ()=> {
        props.setLoading(true)

        let tzoffset = (new Date()).getTimezoneOffset() * 60000;

        let startDateNormal = startDate ? (new Date(startDate - tzoffset)).toISOString() : null
        let endDateNormal = endDate ? (new Date(endDate - tzoffset)).toISOString() : null

        console.log(startDateNormal, endDateNormal)

        const config = {
            method: 'get',
            url: `${apiConfig.url}/statistic/users`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            },
            params: {
                user_id: selectOperator ? selectOperator.value : null,
                date_from: startDateNormal,
                date_to: endDateNormal,
            }
        };
        try {
            const response = await axios(config);
            console.log(response)

            let response_users= await getUsers()
            let operatorsOptions = [
                {value: null, label: "Без оператора"}
            ]

            for (let i = 0; i < response_users.data.length; i++) {
                operatorsOptions.push(
                    {value: response_users.data[i].id, label: response_users.data[i].name}
                )
            }
            setSelectOperatorsAll(operatorsOptions)

            setResponseOperatorsStat(response.data)

            props.setLoading(false)
        } catch (error) {
            console.error('error', error);
        }
    }

    let users_stats = []
    if (responseOperatorsStat) {
        let respUsers = responseOperatorsStat
        for(let y=0; y<respUsers.length; y++) {
            let byDays = []
            let respByDays = respUsers[y].daily_stat
            for(let z=0; z<respByDays.length; z++) {
                let ob_key = Object.keys(respByDays[z])[0]
                byDays.push(
                  <tr style={{borderBottomWidth: 1}}>
                      <td style={{textAlign: "center"}}>{ob_key}</td>
                      <td style={{textAlign: "center"}}>{respByDays[z][ob_key].in_call.time}</td>
                      <td style={{textAlign: "center"}}>{respByDays[z][ob_key].moved_away.time}</td>
                      <td style={{textAlign: "center"}}>{respByDays[z][ob_key].ready.time}</td>
                      <td style={{textAlign: "center"}}>{respByDays[z][ob_key].wait_lead.time}</td>
                      <td style={{textAlign: "center"}}>{respByDays[z][ob_key].education.time}</td>
                      <td style={{textAlign: "center"}}>{respByDays[z][ob_key].all_time.time}</td>
                      <td style={{textAlign: "center"}}>{respByDays[z][ob_key].in_work.time}</td>
                      <td style={{textAlign: "center"}}>{respByDays[z][ob_key].leads}</td>
                      <td style={{textAlign: "center"}}>{respByDays[z][ob_key]?.avg_ready?.toFixed(2)}</td>
                  </tr>
                )
            }
            users_stats.push(
              <div style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    borderWidth: 1,
                    marginBottom: 10,
                    marginTop: 10, backgroundColor: "rgb(246 246 251)"}}>
                    <div style={{fontSize: 19, marginLeft: 5}}>
                        {respUsers[y].created_at.split("T")[0]}
                    </div>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <table style={{width: "100%", borderTopWidth: 1}}>
                            <tr style={{borderBottomWidth: 1}}>
                                <th>Оператор</th>
                                <th>Время разговоров</th>
                                <th>Отошел</th>
                                <th>Ожидание звонка</th>
                                <th>Заполнение лида</th>
                                <th>Обучение</th>
                                <th>Всего на смене</th>
                                <th>В работе</th>
                                <th>Кол-во лидов</th>
                                <th>Ср. время ожидания</th>
                            </tr>
                            {byDays}
                        </table>
                    </div>
                </div>
            )
        }
    }

    useEffect(() => {
        getOperatorsStat()
    }, [])

    return (
        <div style={{display: "flex", flex: 1, flexDirection: "column", overflow: "auto", paddingRight: 20, paddingLeft: 20}}>
            <div style={{display: "flex", flexDirection: "row", paddingTop: 20, paddingBottom: 20, alignItems: "center"}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginRight: 20}}>
                    <div style={{marginRight: 10}}>
                        Оператор:
                    </div>
                    <Select
                        onChange={(item) => {
                            setSelectOperator(item)
                        }}
                        options={selectOperatorsAll}
                    />
                </div>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div>
                        Дата от:
                    </div>

                    <div style={{display: "flex", marginRight: 20, marginLeft: 10}}>
                        <DateTimePicker
                            onChange={(date: any) => {
                                date ? setStartDate(new Date(date)) : setStartDate(null)
                            }}
                            value={startDate}
                            disableClock={true}
                        />
                    </div>
                    <div>
                        Дата до:
                    </div>
                    <div style={{display: "flex", marginLeft: 10}}>
                        <DateTimePicker
                            onChange={(date: any) => {
                                date ? setEndDate(new Date(date)) : setEndDate(null)
                            }}
                            value={endDate}
                            disableClock={true}
                        />
                    </div>
                </div>
                <button
                    onClick={() => {
                        getOperatorsStat()
                    }}
                    style={{
                        display: "flex",
                        height: 40,
                        borderRadius: 5,
                        justifyContent: "center",
                        alignItems: 'center',
                        borderWidth: 1,
                        borderColor: "black",
                        marginLeft: 60
                    }}>
                    Применить фильтр
                </button>
            </div>
            <div style={{display: "flex", flex: 1, flexDirection: "column"}}>
                {users_stats}
            </div>
        </div>
    )
}


const CallsStatComp = (props: any) => {
    const [responseCallsStat, setResponseCallsStat]: [any, any] = useState(null)
    const [page, setPage] = useState(0)
    const [maxPage, setMaxPage] = useState(0)
    const [selectOperator, setSelectOperator]: [any, any] = useState(null)
    const [selectOperatorsAll, setSelectOperatorsAll]: [any, any] = useState([])
    const [selectProject, setSelectProject]: [any, any] = useState(null)
    const [selectProjectsAll, setSelectProjectsAll]: [any, any] = useState([])
    const [selectPhoneFilter, setPhoneFilter]: [any, any] = useState([])
    const [startDate, setStartDate]: [any, any] = useState(new Date().setHours(0, 0));
    const [endDate, setEndDate]: [any, any] = useState(new Date());


    const getUsers = async () => {
        const config = {
            method: 'get',
            url: `${apiConfig.url}/auth/list`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            return await axios(config);
        } catch (error) {
            return {
                data: []
            }
            console.error('error', error);
        }
    }
    const getProjects = async () => {
        props.setLoading(true)
        const config = {
            method: 'get',
            url: `${apiConfig.url}/project/list`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            return await axios(config);
        } catch (error) {
            console.error('error', error);
            return {
                data: []
            }
        }
    }

    const getCallsStat = async () => {
        props.setLoading(true)
        let tzoffset = (new Date()).getTimezoneOffset() * 60000;

        let startDateNormal = startDate ? (new Date(startDate - tzoffset)).toISOString() : null
        let endDateNormal = endDate ? (new Date(endDate - tzoffset)).toISOString() : null

        console.log(startDateNormal, endDateNormal)
        console.log(selectPhoneFilter)

        const config = {
            method: 'get',
            url: `${apiConfig.url}/statistic/calls`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            },
            params: {
                size: 100,
                page: page,
                phone: selectPhoneFilter,
                user_id: selectOperator ? selectOperator.value : null,
                project_id: selectProject ? selectProject.value : null,
                datetime_from: startDateNormal,
                datetime_to: endDateNormal,
            }
        };
        try {
            const response = await axios(config);
            setResponseCallsStat(response.data.calls)

            let response_users= await getUsers()
            let operatorsOptions = [
                {value: null, label: "Без оператора"}
            ]

            for (let i = 0; i < response_users.data.length; i++) {
                operatorsOptions.push(
                    {value: response_users.data[i].id, label: response_users.data[i].name}
                )
            }
            setSelectOperatorsAll(operatorsOptions)

            let response_projects = await getProjects()
            let projectsOptions = [
                {value: null, label: "Без проекта"}
            ]

            for (let i = 0; i < response_projects.data.length; i++) {
                projectsOptions.push(
                    {value: response_projects.data[i].id, label: response_projects.data[i].name}
                )
            }
            setSelectProjectsAll(projectsOptions)

            props.setLoading(false)
            setMaxPage(response.data.pages)
        } catch (error) {
            console.error('error', error);
        }
    }

    let stats = [
        <tr style={{borderBottomWidth: 1}}>
            <th>id</th>
            <th>phone</th>
            <th>status</th>
            <th>dialing_duration</th>
            <th>project</th>
            <th>user</th>
            <th>date</th>
            <th>audio_file</th>
        </tr>
    ]
    if (responseCallsStat) {
        for(let i=0; i<responseCallsStat.length; i++) {
            let callDate = new Date(Date.parse(responseCallsStat[i].created_at))
            stats.push(
                <tr key={i} style={{borderBottomWidth: 1}}>
                    <td style={{textAlign: "center"}}>
                        {responseCallsStat[i].id}
                    </td>
                    <td style={{textAlign: "center"}}>
                        {responseCallsStat[i].phone}
                    </td>
                    <td style={{textAlign: "center"}}>
                        {responseCallsStat[i].status}
                    </td>
                    <td style={{textAlign: "center"}}>
                        {responseCallsStat[i].dialing_duration}
                    </td>
                    <td style={{textAlign: "center"}}>
                        {responseCallsStat[i].project?.name}
                    </td>
                    <td style={{textAlign: "center"}}>
                        {responseCallsStat[i].user?.name}
                    </td>
                    <td style={{textAlign: "center"}}>
                        {callDate.toLocaleString("ru")}
                    </td>
                    <td style={{display: "flex", justifyContent: "center"}}>
                        {responseCallsStat[i].audio_file ? <audio style={{height: 20}} controls src={responseCallsStat[i].audio_file} /> : "Нет записи"}
                    </td>
                </tr>
            )
        }
    }

    useEffect(() => {
        getCallsStat()
    }, [page])

    return (
        <div style={{display: "flex", flexDirection: "row", paddingLeft: 20}}>
            <div style={{display: "flex", marginRight: 20, width: 200, flexDirection: "column"}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{fontSize: 18, marginBottom: 20}}>
                        Фильтры
                    </div>
                    <div>
                        <div>
                            Телефон
                        </div>
                        <input style={{width: "100%", borderWidth: 1}} onChange={(item) => {setPhoneFilter(item.target.value)}} />
                    </div>
                    <div>
                        <div>
                            Проект
                        </div>
                        <Select
                            onChange={(item) => {setSelectProject(item)}}
                            options={selectProjectsAll}
                        />
                    </div>
                    <div>
                        <div>
                            Оператор
                        </div>
                        <Select
                            onChange={(item) => {setSelectOperator(item)}}
                            options={selectOperatorsAll}
                        />
                    </div>
                    <div style={{display: "flex", flex: 1, flexDirection: "column"}}>
                        <div>
                            Дата от
                        </div>

                        <div style={{display: "flex", flex: 1}}>
                            <DateTimePicker
                                onChange={(date: any) => {date ? setStartDate(new Date(date)) : setStartDate(null)}}
                                value={startDate}
                                disableClock={true}
                            />
                        </div>
                        <div>
                            Дата до
                        </div>
                        <div style={{display: "flex", flex: 1}}>
                            <DateTimePicker
                                onChange={(date: any) => {date ? setEndDate(new Date(date)) : setEndDate(null)}}
                                value={endDate}
                                disableClock={true}
                            />
                        </div>
                    </div>
                </div>
                <button
                    onClick={() => {page !== 0 ? setPage(0) : getCallsStat()}}
                    style={{
                        display: "flex",
                        height: 50,
                        justifyContent: "center",
                        alignItems: 'center',
                        borderWidth: 1,
                        borderColor: "black",
                        marginTop: 20
                }}>
                    Применить фильтр
                </button>
            </div>
            <div style={{display: "flex", flex: 1, height: 'calc(100vh - 110px)', flexDirection: "column", borderLeftWidth: 1}}>
                <div style={{display: "flex", flex: 1, flexDirection: "column", overflow: "auto"}}>
                    <table style={{}}>
                        {stats}
                    </table>
                </div>
                <div style={{display: "flex", height: 50, alignItems: "center", justifyContent: "center", borderTopWidth: 1, borderColor: "black"}}>
                    <button
                        onClick={() => {
                            if (page > 0) {
                                setPage(page - 1)
                            }
                        }}
                        style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "center"
                        }}>
                        Предыдущая
                    </button>
                    <div style={{display: "flex", flex: 1, justifyContent: "center"}}>
                        {page+1}/{maxPage}
                    </div>
                    <button
                        onClick={() => {
                            if (maxPage > page+1) {
                                setPage(page + 1)
                            }
                        }}
                        style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "center"
                        }}>
                        Следующая
                    </button>
                </div>
            </div>
        </div>
    )
}


export default StatisticPage;
