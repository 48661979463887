import React, {useEffect, useState} from "react";
import {apiConfig} from "../../config";
import axios from "axios";
import Select from "react-select";
import DateTimePicker from "react-datetime-picker";


const ProjectCallsStatComp = (props: any) => {
    const [responseProjectsCallsStat, setResponseProjectsCallsStat]: [any, any] = useState(null)
    const [selectOperator, setSelectOperator]: [any, any] = useState(null)
    const [selectOperatorsAll, setSelectOperatorsAll]: [any, any] = useState([])
    const [startDate, setStartDate]: [any, any] = useState(new Date().setHours(0, 0));

    const currentDate: any = (new Date())
    currentDate.setDate(currentDate.getDate() + 1);
    const [endDate, setEndDate]: [any, any] = useState(currentDate.setHours(0,0,0));

    const getUsers = async () => {
        const config = {
            method: 'get',
            url: `${apiConfig.url}/auth/list`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            }
        };
        try {
            return await axios(config);
        } catch (error) {
            return {
                data: []
            }
            console.error('error', error);
        }
    }

    const  getOperatorsStat = async ()=> {
        props.setLoading(true)

        let tzoffset = (new Date()).getTimezoneOffset() * 60000;

        let startDateNormal = startDate ? (new Date(startDate - tzoffset)).toISOString() : null
        let endDateNormal = endDate ? (new Date(endDate - tzoffset)).toISOString() : null

        console.log(startDateNormal, endDateNormal)

        const config = {
            method: 'get',
            url: `${apiConfig.url}/statistic/project_rate`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token_caltatToki')}`
            },
            params: {
                user_id: selectOperator ? selectOperator.value : null,
                date_from: startDateNormal,
                date_to: endDateNormal,
            }
        };
        try {
            const response = await axios(config);
            console.log(response)

            let response_users= await getUsers()
            let operatorsOptions = [
                {value: null, label: "Без оператора"}
            ]

            for (let i = 0; i < response_users.data.length; i++) {
                operatorsOptions.push(
                    {value: response_users.data[i].id, label: response_users.data[i].name}
                )
            }
            setSelectOperatorsAll(operatorsOptions)

            setResponseProjectsCallsStat(response.data)

            props.setLoading(false)
        } catch (error) {
            console.error('error', error);
        }
    }

    let stat = []
    if (responseProjectsCallsStat) {
        for(let y=0; y<responseProjectsCallsStat.length; y++) {
            stat.push(
              <tr style={{borderBottomWidth: 1}}>
                  <td style={{textAlign: "center"}}>{responseProjectsCallsStat[y]?.dt}</td>
                  <td style={{textAlign: "center"}}>{responseProjectsCallsStat[y]?.dialing}</td>
                  <td style={{textAlign: "center"}}>{responseProjectsCallsStat[y]?.cnt_avto}</td>
                  <td style={{textAlign: "center"}}>{responseProjectsCallsStat[y]?.cnt_answer_pr}</td>
                  <td style={{textAlign: "center"}}>{responseProjectsCallsStat[y]?.cnt_lead}</td>
                  <td style={{textAlign: "center"}}>{responseProjectsCallsStat[y]?.outgoing}</td>
                  <td style={{textAlign: "center"}}>{responseProjectsCallsStat[y]?.answer}</td>
                  <td style={{textAlign: "center"}}>{responseProjectsCallsStat[y]?.lead}</td>
                  <td style={{textAlign: "center"}}>{responseProjectsCallsStat[y]?.outbound_call}</td>
                  <td style={{textAlign: "center"}}>{responseProjectsCallsStat[y]?.answero}</td>
              </tr>
            )
        }
    }

    useEffect(() => {
        getOperatorsStat()
    }, [])

    return (
        <div style={{display: "flex", flex: 1, flexDirection: "column", overflow: "auto", paddingRight: 20, paddingLeft: 20}}>
            <div style={{display: "flex", flexDirection: "row", paddingTop: 20, paddingBottom: 20, alignItems: "center"}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginRight: 20}}>
                    <div style={{marginRight: 10}}>
                        Оператор:
                    </div>
                    <Select
                        onChange={(item) => {
                            setSelectOperator(item)
                        }}
                        options={selectOperatorsAll}
                    />
                </div>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div>
                        Дата от:
                    </div>

                    <div style={{display: "flex", marginRight: 20, marginLeft: 10}}>
                        <DateTimePicker
                            onChange={(date: any) => {
                                date ? setStartDate(new Date(date)) : setStartDate(null)
                            }}
                            value={startDate}
                            disableClock={true}
                        />
                    </div>
                    <div>
                        Дата до:
                    </div>
                    <div style={{display: "flex", marginLeft: 10}}>
                        <DateTimePicker
                            onChange={(date: any) => {
                                date ? setEndDate(new Date(date)) : setEndDate(null)
                            }}
                            value={endDate}
                            disableClock={true}
                        />
                    </div>
                </div>
                <button
                    onClick={() => {
                        getOperatorsStat()
                    }}
                    style={{
                        display: "flex",
                        height: 40,
                        borderRadius: 5,
                        justifyContent: "center",
                        alignItems: 'center',
                        borderWidth: 1,
                        borderColor: "black",
                        marginLeft: 60
                    }}>
                    Применить фильтр
                </button>
            </div>
            <div style={{display: "flex", flex: 1, flexDirection: "column"}}>
                <div style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    borderWidth: 1,
                    marginBottom: 10,
                    marginTop: 10, backgroundColor: "rgb(246 246 251)"
                }}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <table style={{width: "100%", borderTopWidth: 1}}>
                            <tr style={{borderBottomWidth: 1}}>
                                <th>Дата</th>
                                <th>Процент дозвона</th>
                                <th>Процент автоответчиков</th>
                                <th>Процент прожигаемой базы</th>
                                <th>Конверсия</th>
                                <th>Исходящих</th>
                                <th>Принятых</th>
                                <th>Лидов</th>
                                <th>Входящих</th>
                                <th>Обработанных входящих</th>
                            </tr>
                            {stat}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectCallsStatComp
